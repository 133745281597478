export default [
  {
    title: 'Clients',
    route: 'clients',
    icon: 'UsersIcon',
  },
  {
    title: 'Planes',
    route: 'plans',
    icon: 'LayersIcon',
  },
  {
    title: 'Memberships',
    route: 'memberships',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Attendances',
    route: 'attendances',
    icon: 'CalendarIcon',
  },
  {
    title: 'Routines',
    route: 'Routines',
    icon: 'ActivityIcon',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'LockIcon',
  }
]
